<template>
  <b-row class="badges-wrapper">
    <b-badge
      v-if="hasReclamationInProject"
      variant="danger"
      class="white-space-wrap"
    >
      Reclamation in project
    </b-badge>
    <b-badge
      v-if="partnerHasReclamations"
      variant="warning"
      class="white-space-wrap"
    >
      Partner has reclamations
    </b-badge>
    <b-badge
      v-if="partnerIsOverFiveThousand"
      variant="info"
      class="white-space-wrap"
    >
      Missing or Invalid Freistellungsauftrag
    </b-badge>
  </b-row>
</template>

<script>
export default {
  props: {
    hasReclamationInProject: Boolean,
    partnerHasReclamations: Boolean,
    partnerIsOverFiveThousand: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.badges-wrapper {
  gap: 5px;
  margin-top: 5px;

  & .white-space-wrap {
    white-space: normal;
  }
}
</style>
